@import "./_react.scss";
@import "../pages/Home/_home.scss";
@import "../pages/About/_about.scss";
@import "../components/_corner-nav.scss";
// WAGMI FONT
@font-face {
  font-family: "SG10";
  src: local("SG10"), url(../assets/fonts/SG10.ttf) format("truetype");
}

// ARRANGEMENT
@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

html {
  text-align: center;
  padding-top: 5em;
  padding-bottom: 5em;
  padding-left: 2em;
  padding-right: 2em;
}
