.members-grid-member-card {
  width: 40px;
  height: 40px;
  padding: 4px;
  border-radius: 100%;
  box-shadow: 7px 7px 15px rgba(55, 84, 170, 0.15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0.2),
    inset 7px 7px 15px rgba(55, 84, 170, 0),
    inset -7px -7px 20px rgba(255, 255, 255, 0),
    0px 0px 4px rgba(255, 255, 255, 0);
  // hover pop out
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
    0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
  &:hover {
    transform: scale(1.1);
  }
}

.members-grid-member-card-image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.members-grid {
  //   arrange images random in a blob
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  list-style: none;
  //   spacing
  & > * {
    margin: 5px 5px;
  }
  //   arrange images random in a blob
  //   from file static/images/logo.png
}

.page-about {
  padding-bottom: 200px;
  padding-top: 200px;
}

.members-grid-member-card-id {
  // add spacing for when this div contains nothing
  //   from file static/images/logo.png
  padding-top: 20px;
  border: 2px solid #e8e8f3;
}
