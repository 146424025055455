html {
  background: #e8e8f3;
  height: 100vh;
  display: grid;
  place-content: center;
  -webkit-tap-highlight-color: transparent;
}

.home-container {
  width: fit-content;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.home-header {
  position: absolute;
  top: 10%;
}

.menu {
  position: relative;
  top: 40px;
  padding: 30px;
  background: #e8e8f3;
  border-radius: 100%;
  //   from file static/images/logo.png
  background: url("../../../public/static/images/logo.svg") #e8e8f3;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
  box-shadow: 7px 7px 15px rgba(55, 84, 170, 0.15),
    -7px -7px 20px rgba(255, 255, 255, 1),
    inset 0px 0px 4px rgba(255, 255, 255, 0.2),
    inset 7px 7px 15px rgba(55, 84, 170, 0),
    inset -7px -7px 20px rgba(255, 255, 255, 0),
    0px 0px 4px rgba(255, 255, 255, 0);
  &::before,
  &::after {
    background: #c3c2c7;
    border-radius: 5px;
    width: 30px;
    height: 5px;
    position: absolute;
    left: 16px;
    top: 27px;
    transition: 0.2s ease;
    z-index: 1;
  }
  &::before {
    transform: rotate(0deg);
  }
  &::after {
    transform: rotate(-90deg);
  }
  &.open {
    opacity: 0.9;
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
    .button {
      opacity: 1;
      pointer-events: auto;
      text-decoration: none;
      color: #000;
      &:first-of-type {
        bottom: 40px;
        right: 70px;
        background: #e8e8f3;
      }
      &:nth-of-type(2) {
        bottom: 80px;
        background: #e8e8f3;
        transition-delay: 0.05s;
      }
      &:nth-of-type(3) {
        bottom: -40px;
        right: -70px;
        background: #e8e8f3;
        transition-delay: 0.05s;
      }
      &:nth-of-type(4) {
        bottom: -40px;
        right: 70px;
        background: #e8e8f3;
        transition-delay: 0.05s;
      }
      &:last-of-type {
        bottom: 40px;
        right: -70px;
        background: #e8e8f3;
        transition-delay: 0.1s;
      }
      i {
        // float ontop
        position: absolute;
        // center middle
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
      }
    }
  }
}

.button {
  padding: 30px;
  border-radius: 50%;
  cursor: pointer;
  background: #e8e8f3;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  box-shadow: inherit;
  transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity,
    0.2s cubic-bezier(0.08, 0.82, 0.17, 1) transform;
  &:hover {
    transform: scale(1.1);
  }
}
